<template>
  <div
    v-epi-edit="'MainContent'" 
    class="pt-24 sm:pt-56 full-width-content-area overflow-x-hidden"
  >
    <EpiserverContentArea :model="model.mainContent" :full-width="true" />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import * as Sentry from '@sentry/vue';
import { useCartStore } from '~/store/cart';
import { CartType } from '~/api-types';
import { useUiStore } from '~/store/ui';
import useDisableScroll from '~/composables/useDisableScroll';
import { useGlobalContentStore } from '~/store/globalContent';
import type { JsonLD } from 'nuxt-jsonld/dist/types/index.d';
import type { StartPage } from '~/content-types';

const globalContentStore = useGlobalContentStore();
const { disableScroll } = useDisableScroll();
const uiStore = useUiStore();
const cartStore = useCartStore();
const host = globalContentStore.host;
const company = globalContentStore.marketSettings.company;

const props = defineProps<{
  model: StartPage;
}>();

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  'image': props.model.meta?.openGraphImage?.url.includes(host) ? `${props.model.meta?.openGraphImage?.url}` : `${host}${props.model.meta?.openGraphImage?.url}`,
  'url':  host,
  'logo': `${host}/logos/swm-logotype-inverted.svg`,
  'name': company.name,
  'description': 'Svenskt snus och tobak online från Swedish Match alla varumärken.',
  'address': {
    '@type': 'PostalAddress',
    'streetAddress': 'Sveavägen 44',
    'addressLocality': 'Stockholm',
    'addressCountry': 'SE',
    'addressRegion': 'Stockholm',
    'postalCode': '11885',
  },
  'vatID': company.vatRegistrationNumber,
} as JsonLD;

useJsonld(jsonLd);

onMounted(async() => {
  nextTick(async() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);

    let orderNumber = params.get('orderNo') as string;

    if (process.client && orderNumber) {
      try {
        await globalContentStore.fetchAntiForgeryToken();

        orderNumber = atob(orderNumber);
        var success = await cartStore.repurchaseOrderFromOrderNo(orderNumber, CartType.Standard);

        if (success) {
          uiStore.setShowCartMenu(true);
          disableScroll();

          window.dataLayer?.push({
            event: 'Cart',
            category: 'cart',
            action: 'view',
          });
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  });
});
</script>
