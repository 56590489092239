<template>
  <div 
    ref="sentinel"
    class="md:layout-container mb-24 md:mb-64"
    :class="{
      'min-h-[280px] md:min-h-[400px]': (showQuickBuy || model.useBigLayout || model.useBigLayoutTransparent),
      'md:min-h-[300px]': !showQuickBuy,
      'min-h-[420px]': !model.useSlimLayout && showQuickBuy && showExcerpt
      
    }"
  >
    <div class="relative">
      <div
        class="px-24 md:px-0 sm:flex justify-between mb-16 md:min-h-32"
      >
        <GlobalsLinkHelper
          v-if="model.blockLink && model.header"
          :to="model.blockLink.href"
          :target="model.blockLink.target ? model.blockLink.target : '_self'"
          class="block mb-8 sm:mb-0"
        >
          <h2 class="text-lg sm:text-xl leading-base sm:leading-lg">
            {{ model.header }}
          </h2>
        </GlobalsLinkHelper>
        <h2 v-else-if="model.header" class="block mb-8 sm:mb-0 text-lg sm:text-xl leading-base sm:leading-lg">
          {{ model.header }}
        </h2>
        <GlobalsLinkHelper
          v-if="
            (model.blockLink && productsResult && productsResult.products.length < 7) ||
              (model.blockLink && (isTablet || isMobile))
          "
          :to="model.blockLink?.href"
          :target="model.blockLink.target ? model.blockLink.target : '_self'"
          class="arrow-link self-end text-xs sm:text-sm sm:ml-auto"
        >
          {{ model.blockLink?.text }}
        </GlobalsLinkHelper>
      </div>
      <div
        v-if="productsResult"
        ref="scrollableDiv"
        class="flex pb-24 px-24 gap-x-16 sm:gap-x-24 md:gap-x-24 md:px-0 overflow-scroll no-scrollbar"
        :class="{
          'sm:grid-cols-4 sm:grid': model.useBigLayout || model.useBigLayoutTransparent,
          'md:grid-cols-6 md:grid': !model.useBigLayout && !model.useBigLayoutTransparent,
        }"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseup="handleMouseUp"
      >
        <div
          v-for="(product, index) in productsResult.products.slice(0, model.useBigLayout || model.useBigLayoutTransparent ? 4 : 6)"
          :key="index"
          class="flex-shrink-0 md:w-full w-[47%] sm:w-[21.5%]"
          :class="{
            'sm:!w-full': model.useBigLayout || model.useBigLayoutTransparent
          }"
        >
          <ProductSpot
            v-if="product"
            :product="product"
            :show-excerpt="showExcerpt"
            :show-quickbuy="showQuickBuy"
            :small-layout="true"
            :slim-layout="model.useSlimLayout"
            :big="model.useBigLayout && !isMobile"
            :load="index < 2 ? 'eager' : 'lazy'"
            :should-prelaod="index < 3"
            :is-product-block="true"
            :big-transparent="model.useBigLayoutTransparent"
            :simple-product-listing="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductListingBlock } from '~/content-types';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';
import { useGlobalContentStore } from '~/store/globalContent';
import { type IProductsResult } from '~/api-types';
import { onMounted, ref } from 'vue';

const route = useRoute();
const { isMobile, isTablet } = storeToRefs(useUiStore());
const { apiGet } = useApiFetch();
const productsResult = ref<IProductsResult>();

const props = defineProps<{
  model: ProductListingBlock;
}>();

const showQuickBuy = computed<boolean>(()=> {
  if (isMobile.value && props.model.useSlimLayout) {
    return false;
  }
  return props.model.showQuickbuy || false;
});
const showExcerpt = computed<boolean>(()=> {
  if (isMobile.value && props.model.useSlimLayout) {
    return false;
  }
  return props.model.showExcerpt || false;
});

const mapOrder = (array, order) => {
  array = order.map((p) => {
    return array.find((product) => product.variants.some((variant) => variant.partNo === p.partNo));
  }).filter((item) => {
    return typeof item !== 'undefined' && item !== null;
  });
  return array;
};

const scrollableDiv = ref(null);
const isDragging = ref(false);
let startX = null;

const handleMouseDown = (event) => {
  isDragging.value = true;
  startX = event.clientX - scrollableDiv.value?.offsetLeft;
};

const handleMouseMove = (event) => {
  if (!isDragging.value) return;

  event.preventDefault();
  
  if (scrollableDiv.value) {
    const x = event.clientX - scrollableDiv.value.offsetLeft;
    const walk = (x - startX) * 2;
    scrollableDiv.value.scrollLeft -= walk;
    startX = x;
  }
};

const handleMouseUp = () => {
  isDragging.value = false;
};

onMounted(async()=> {
  // await loadData();

  if (scrollableDiv.value) {
    scrollableDiv.value.addEventListener('dragstart', (event) => event.preventDefault());
  }
});

const loadData = async() => {
  const marketSettings = useGlobalContentStore().marketSettings;
  let requestUrl = `products?countryCode=${marketSettings.countryCode}&language=${marketSettings.contentLanguage}&url=${route.path}`;
  // Manually selected products
  if (props.model.products?.length) {
    props.model.products.forEach((item) => {
      requestUrl += `&PartNumbers=${item.partNo}`;
    });
  } else {
    if (props.model.filters) {
      Object.entries(props.model.filters).forEach((filter) => {
        const keyWord = filter[0].charAt(0).toUpperCase() + filter[0].slice(1);
        filter[1].split(',').forEach((item: string)=> {
          requestUrl += `&${keyWord}=${item}`;
        });
      });
    }
  }
  productsResult.value = await apiGet<IProductsResult>(requestUrl);

  if (productsResult.value && props.model.products) {
    productsResult.value.products = mapOrder(productsResult.value.products, props.model.products);
  }
};

await loadData();

</script>

<style>
.product-listing-swiper {
  .swiper-button-next,
  .swiper-button-prev {
    @apply right-0 top-0 left-auto border border-grey400 h-32 w-32 -translate-y-28;

    &:after {
      @apply h-18 w-18;
    }
  }

  .swiper-button-prev {
    @apply right-48;
  }

  .swiper-button-disabled {
    @apply opacity-50 md:flex;
  }

  &.hide-swiper-nav {
    .swiper-button-next,
    .swiper-button-prev {
      @apply hidden;
    }
  }
}
</style>
